.footer {
  position: relative;
  top: 1060px;
  width: 100%;
  height: 610px;
  background-color: #f0f3f4;
}

.details1396 {
  text-align: left;
  position: relative;
  left: 80px;
}

.forDetail {
  font-size: 20px;
  color: #747171;
  line-height: 22px;
  font-family: "Nunito", sans-serif;
}

.instructor {
  position: relative;
  top: 45px;
  left: 0px;
  text-align: left;
  color: #747171;

  h4 {
    font-size: 20px;
    padding-bottom: 10px;
  }
}

.explore {
  position: relative;
  top: 45px;
  left: 0px;
  text-align: left;
  color: #747171;

  h4 {
    font-size: 20px;
    padding-bottom: 10px;
  }
}

.inputMailFooter {
  width: 50%;
  height: 61px;
  border: none;
  background-color: #fbfaf1;
  padding-left: 38px;
}

.inputMailFooter1396 {
  position: absolute;
  right: 210px;
  width: 50%;
  height: 61px;
  border: none;
  top: 105px;
  background-color: #fbfaf1;
  padding-left: 38px;
}

.emailHexFooter {
  z-index: 99;
  font-size: 17px;
}

.sendEmailFooter {
  background-color: #ffc107;
  width: 60px;
  height: 61px;
}

.sendImg {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.downloadApp {
  text-align: left;
}

.footerLast {
  position: absolute;
  background-color: #020239;
  top: 460px;
  width: 82%;
  height: 150px;
  left: 9%;
  border-radius: 10px 10px 0px 0px;
}

.akName {
  color: #d6cfcf;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  left: 30px;
  font-family: "Nunito", sans-serif;
}

.akNamePara {
  color: #d6cfcf;
  text-align: left;
  font-size: 12px;
  line-height: 20px;
  position: relative;
  left: 30px;
  bottom: 9px;
  font-family: "Nunito", sans-serif;
}

.headphone {
  width: 75px;
  height: 75px;
  position: relative;
  right: 0px;
}

.haveQuestion {
  color: #d6cfcf;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  left: 20px;
  bottom: 15px;
  font-weight: 500;
  font-family: "Nunito", sans-serif;
}

.mobileNumber {
  position: relative;
  left: 75px;
  bottom: 25px;
}

.fonts {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
}

.borderRight {
  border-right: 1px solid #d6cfcf;
  height: 80px;
}

.akName1 {
  display: none;
}

@media only screen and (max-width: 360px) {
  .downloadAppText {
    font-size: 11px;
    padding-top: 11px;

    h2 {
      font-size: 18px;
    }
  }

  .newFooter {
    background-color: #f0f3f4;
    width: 100%;
    padding: 50px 30px 0px 30px !important;
    margin-top: 60px;
  }

  .footer {
    position: relative;
    top: 1060px;
    width: 100%;
    height: 700px !important;
    background-color: #f7f8fc !important;
    bottom: 0px !important;
  }

  .footerLogo {
    width: 310px;
    height: 100px;
    position: relative;
    left: 0px;
  }

  .details {
    text-align: left;
    position: relative;
    left: 0px;
  }

  .details1396 {
    text-align: left;
    position: relative;
    left: 0px;
  }

  .iconFlexDiv {
    display: flex;
    flex-direction: row;
    margin-top: -30px;
  }

  .fonts {
    font-family: "Nunito", sans-serif;
    font-size: 14px;
  }

  .dispalyAddlink {
    display: flex;
    flex-direction: row;
  }

  .instructor {
    position: relative;
    top: 10px !important;
    left: 0px;
    text-align: left;
    color: #747171;

    h4 {
      font-size: 14px !important;
      padding-bottom: 10px;
      line-height: 1px;
    }
  }

  .explore {
    position: relative;
    top: 10px !important;
    left: 0px;
    text-align: left;
    color: #747171;

    h4 {
      font-size: 14px !important;
      padding-bottom: 10px;
      line-height: 1px;
    }
  }

  .footerLast {
    position: absolute;
    background-color: #020239;
    top: 750px !important;
    width: 100% !important;
    height: 50% !important;
    left: 0px !important;
    border-radius: 0px !important;
  }

  .emailHexFooter {
    position: relative;
    top: 18px !important;
    z-index: 99;
    font-size: 17px;
    left: 15px;
  }

  .footerBottom {
    padding-top: 12px !important;
  }

  .sendEmailFooter {
    position: absolute;
    top: 0px !important;
    right: 32px !important;
    background-color: #ffc107;
    width: 41px;
    height: 44px;
  }

  .sendImg {
    width: 25px !important;
    height: 25px !important;
    position: relative;
    top: 10px !important;
    left: 9px !important;
    cursor: pointer;
  }

  .inputMailFooter {
    width: 50%;
    height: 44px;
    border: none;
    background-color: #fbfaf1;
    padding-left: 38px;
  }

  .inputMailFooter1396 {
    position: absolute;
    // right: 0px !important;
    width: 50%;
    height: 61px;
    border: none;
    top: 0px !important;
    background-color: #fbfaf1;
    padding-left: 38px;
  }

  // .sendEmailFooter {
  //     position: absolute;
  //     top: 45px !important;
  //     right: 85px !important;
  //     background-color: #ffc107;
  //     width: 60px;
  //     height: 61px;
  // }

  .downloadApp {
    position: relative;
    top: 80px;
    text-align: left;
    left: 0px;

    h2 {
      font-size: 18px !important;
      font-family: "Raleway", sans-serif;
      margin-top: 0px !important;
      padding-top: 0px;
    }
  }

  .borderRight {
    border: none;
  }

  .akName {
    display: none;
  }

  .haveQuestion {
    color: #899182 !important;
    text-align: left;
    font-size: 17px !important;
    line-height: 20px;
    position: relative;
    left: 20px;
    bottom: 15px;
    font-weight: 500;
    font-family: "Nunito", sans-serif;
  }

  .haveQuestionMain {
    margin-top: -60px !important;
  }

  .akName1 {
    display: block;
    color: #d6cfcf;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    left: 0px !important;
    font-family: "Nunito", sans-serif;
  }

  .akNamePara {
    color: #d6cfcf;
    text-align: left;
    font-size: 11.5px !important;
    line-height: 20px;
    position: relative;
    left: 0px !important;
    bottom: 9px;
    font-family: "Nunito", sans-serif;
  }

  .mobileNumber {
    position: relative;
    font-size: 15px;
    left: 75px;
    bottom: 25px;
    color: #d6cfcf;
  }

  .subscribe {
    margin-top: 21px;
    display: inline-flex;
  }

  .footerLast {
    margin-top: -49px;
  }
}
@media only screen and (max-width: 1000px) {
  .mailbox {
    position: relative;
    width: 100%;
    margin-top: 11px !important;
  }
  .downloadAppText {
    font-size: 11px;
    padding-top: 11px;

    h2 {
      font-size: 18px;
    }
  }

  .newFooter {
    background-color: #f0f3f4;
    width: 100%;
    padding: 50px 30px 0px 30px !important;
    margin-top: 60px;
  }

  .footer {
    position: relative;
    top: 1060px;
    width: 100%;
    height: 700px !important;
    background-color: #f7f8fc !important;
    bottom: 0px !important;
  }

  .footerLogo {
    width: 310px;
    height: 100px;
    position: relative;
    left: 0px;
  }

  .details {
    text-align: left;
    position: relative;
    left: 0px;
  }

  .details1396 {
    text-align: left;
    position: relative;
    left: 0px;
  }

  .iconFlexDiv {
    display: flex;
    flex-direction: row;
    margin-top: -30px;
  }

  .fonts {
    font-family: "Nunito", sans-serif;
    font-size: 14px;
  }

  .dispalyAddlink {
    display: flex;
    flex-direction: row;
  }

  .instructor {
    position: relative;
    top: 10px !important;
    left: 0px;
    text-align: left;
    color: #747171;

    h4 {
      font-size: 14px !important;
      padding-bottom: 10px;
      line-height: 1px;
    }
  }

  .explore {
    position: relative;
    top: 10px !important;
    left: 0px;
    text-align: left;
    color: #747171;

    h4 {
      font-size: 14px !important;
      padding-bottom: 10px;
      line-height: 1px;
    }
  }

  .footerLast {
    position: absolute;
    background-color: #020239;
    top: 750px !important;
    width: 100% !important;
    height: 50% !important;
    left: 0px !important;
    border-radius: 0px !important;
  }

  .emailHexFooter {
    position: relative;
    top: 18px !important;
    z-index: 99;
    font-size: 17px;
    left: 15px;
  }

  .footerBottom {
    padding-top: 12px !important;
  }

  .sendEmailFooter {
    position: absolute;
    top: 0px !important;
    right: 46px !important;
    background-color: #ffc107;
    width: 41px;
    height: 44px;
  }

  .sendImg {
    width: 25px !important;
    height: 25px !important;
    position: relative;
    top: 10px !important;
    left: 9px !important;
    cursor: pointer;
  }

  .inputMailFooter {
    width: 50%;
    height: 44px;
    border: none;
    background-color: #fbfaf1;
    padding-left: 38px;
  }

  .inputMailFooter1396 {
    position: absolute;
    // right: 0px !important;
    width: 50%;
    height: 61px;
    border: none;
    top: 0px !important;
    background-color: #fbfaf1;
    padding-left: 38px;
  }

  // .sendEmailFooter {
  //     position: absolute;
  //     top: 45px !important;
  //     right: 85px !important;
  //     background-color: #ffc107;
  //     width: 60px;
  //     height: 61px;
  // }

  .downloadApp {
    position: relative;
    top: 80px;
    text-align: left;
    left: 0px;

    h2 {
      font-size: 18px !important;
      font-family: "Raleway", sans-serif;
      margin-top: 0px !important;
      padding-top: 0px;
    }
  }

  .borderRight {
    border: none;
  }

  .akName {
    display: none;
  }

  .haveQuestion {
    color: #899182 !important;
    text-align: left;
    font-size: 17px !important;
    line-height: 20px;
    position: relative;
    left: 20px;
    bottom: 15px;
    font-weight: 500;
    font-family: "Nunito", sans-serif;
  }

  .haveQuestionMain {
    margin-top: -60px !important;
  }

  .akName1 {
    display: block;
    color: #d6cfcf;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    left: 0px !important;
    font-family: "Nunito", sans-serif;
  }

  .akNamePara {
    color: #d6cfcf;
    text-align: left;
    font-size: 11.5px !important;
    line-height: 20px;
    position: relative;
    left: 0px !important;
    bottom: 9px;
    font-family: "Nunito", sans-serif;
  }

  .mobileNumber {
    position: relative;
    font-size: 15px;
    left: 75px;
    bottom: 25px;
    color: #d6cfcf;
  }

  .subscribe {
    margin-top: 21px;
    display: inline-flex;
  }

  .footerLast {
    margin-top: -49px;
  }
}

// newFooter
.newFooter {
  position: relative;
  background-color: #f0f3f4;
  width: 100%;
  padding: 50px 100px 0px 100px;
  margin-top: 60px;
}

.newfooterLogo {
  width: 75%;
}

.Footerdetails {
  text-align: left;
}

.emailHexFooter {
  z-index: 99;
  font-size: 17px;
  position: absolute;
  top: 18px;
  left: 11px;
  color: gray;
}

.mailbox {
  position: relative;
  width: 100%;
  margin-top: 64px;
}

.inputMailFooter {
  width: 69%;
  height: 51px;
  border: none;
  background-color: #fbfaf1;
  padding-left: 38px;
}

.sendEmailFooter {
  background-color: #ffc107;
  width: 60px;
  height: 51px;
  display: inline-flex;
  /* margin-top: 10px; */
  position: absolute;
}

.sendIcon {
  width: 50%;
  height: 50%;
  text-align: center;
  color: #fff;
  margin-top: 17px;
  margin-left: 15px;
}

.downloadAppText {
  // font-size: 16px !important;
  padding-top: 11px;

  h2 {
    font-size: 19px !important;
    text-transform: none !important;
  }
}

.footerBottom {
  background-color: #020239;
  border-radius: 10px 10px 0 0;
  padding: 10px;
  padding-top: 34px;
  margin-top: 35px;
}
@media screen and (min-width:820px) and (max-width: 1180px) {
  .mailbox {
    position: relative;
    width: 142%;
    margin-top: 64px;
    margin-left: -10px;
  }
  .newFooter {
    margin-top: 170px;
  }
}
@media screen and (width: 360px) {
  .newFooter {
    margin-top: 92px;
  }
}

@media screen and (width: 768px) {
  .newFooter {
    margin-top: 92px;
  }
  .sendEmailFooter {
    background-color: #ffc107;
    width: 50px;
    height: 50px;
    display: inline-flex;
    right: -23px !important;
    position: absolute;
  }
  .inputMailFooter {
    width: 84%;
  }
}
