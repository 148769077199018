@media only screen and (width: 360px) {
  .attemptCourse2 {
    text-align: center;
    position: relative;
    top: 70px;
    align-content: center;
  }
  .showing {
    font-size: 15px !important;
    font-weight: 600;
    font-family: "Nunito", sans-serif;
    padding: 8px 0px;
  }
  .overLayWrap {
    background-color: rgba(196, 196, 196, 0.68);
    position: absolute;
    z-index: 99;
    width: 323px;
    height: 165px;
    left: 21px;
    justify-content: center;
    align-items: center;
  }
  .leftCourseLand {
    width: 100% !important;
    text-align: left !important;
    position: relative !important;
    left: 14px !important;
  }

  .showingDetail {
    font-size: 13px !important;
    font-weight: 600;
    margin-top: 8px;
    display: flex;
    margin-left: -14px;
  }

  .batchButtonDetail {
    background-color: #139413;
    color: #fff;
    border: none;
    padding: 7px 7px;
    border-radius: 5px;
    font-size: 10px;
    margin-top: 5px;
  }

  .flex {
    display: flex;
    font-family: Nunito, sans-serif;
    padding-bottom: 10px;
  }

  .flexSmart {
    display: flex;
    padding-bottom: 12px;
  }

  .systemReq {
    font-family: Raleway, sans-serif;
    font-weight: 900;
    font-size: 18px;
  }

  .font2 {
    font-family: Nunito, sans-serif;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 5px;
  }

  .blueBtnDetail {
    font-size: 15px;
    font-weight: 500;
    font-weight: 700;
    height: 40px;
    position: relative;
    width: 140px;
    z-index: 99;
    background-color: #3a529d;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-family: Nunito, sans-serif;
  }

  .grayBtnDetail {
    border-radius: 5px;
    font-family: Nunito, sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-weight: 700;
    height: 40px;
    left: 20px;
    position: relative;
    width: 120px;
    z-index: 9;
    background-color: #fff;
    border: 2px solid #3a529d;
    color: #000;
  }

  .grayBtnDetail1 {
    background-color: #3a529d;
    color: #fff;
    border-radius: 5px;
    font-family: Nunito, sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-weight: 700;
    height: 40px;
    left: 20px;
    position: relative;
    width: 120px;
    z-index: 9;
    border: 1px solid #3a529d;
  }

  .vidArea {
    border-radius: 5px;
    height: 185px;
    margin-bottom: 5px;
    margin-left: 11px;
    margin-top: 28px;
    width: 90%;
  }

  .video-responsive iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .deletePrice {
    color: red;
    right: 23px;
    font-size: 16px;
    font-weight: 500;
    position: relative;
  }

  .offPrice {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    text-align: center;
  }

  .rupeeColor {
    font-size: 16px;
    font-weight: 500;
    position: relative;
    color: #139413;
    left: -11px;
  }

  .buyBtnArea {
    margin-top: 30px;
    text-align: center;
  }

  .buyCourseBtn {
    background-color: #3a529d;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    height: 47px;
    width: 160px;
  }

  .applyBtnArea {
    margin-top: 20px;
    padding-bottom: 25px;
    text-align: center;
  }

  .applyCoupon {
    border-bottom: 1px solid #3a529d;
    color: #3a529d;
    font-family: Nunito, sans-serif;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 7px;
  }

  .pricesNote {
    font-family: Nunito, sans-serif;
    margin-left: 6px;
    width: 90%;
    border-bottom: 1px solid #d9d7d7;
    padding-bottom: 20px;
  }

  .video-responsive {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
  }

  .blueBtnDetail1 {
    background-color: #fff;
    border: 2px solid #3a529d;
    border-radius: 5px;
    color: #000;
    font-family: Raleway, sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-weight: 700;
    height: 40px;
    position: relative;
    width: 140px;
    z-index: 99;
  }

  .font {
    font-family: Nunito, sans-serif;
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 12px;
  }

  .attemptCourseDetail2 {
    padding: 6px 0px;
    border-bottom: 1px solid #ddd;
    width: 100%;
  }

  .attemptCourseDetail3 {
    position: relative;
    top: 45px;
    width: 101% !important;
    height: auto !important;
    border: 1px solid #edeaea;
    background-color: #f1f8ff;

    ul {
      padding-left: 0px;
    }
  }

  .attemptCourseDetail {
    position: relative;
    width: 100%;
    height: auto;
    border-bottom: 1px solid #dedbdb;
    padding: 10px 0px;
  }

  .akBookImg {
    width: 100%;
    height: auto !important;
  }

  .attemptCourseDetail4 {
    position: relative;
    top: 60px !important;
    width: 100% !important;
    height: auto !important;
    padding-bottom: 0px !important;
    border-bottom: 1px solid #e0e0e0;

    .activationProcess {
      color: #3a529d;
    }
  }

  .activate {
    position: relative;
    right: 22px;
    width: 100%;
  }

  .attemptCourseDetail5 {
    position: relative;
    top: 70px;
    width: 100%;
    height: auto;
  }

  .lectureIndex {
    color: #3a529d;
    font-size: 13px;
    font-weight: 600;
    position: relative;
    top: 3px;
  }

  .pdfImg {
    width: 35px;
    height: 35px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 8px;
  }

  .downloadArea {
    background-color: #f5f6f7;
    width: 294px;
    height: 55px;
    border-radius: 5px;
    border: 2px solid #d9d6d6;
    position: relative;
    bottom: 0px;
    left: -28px;
    font-family: "Raleway", sans-serif;
    cursor: pointer;
  }

  .attemptCourseDetail6 {
    position: relative;
    top: 80px;
    width: 100%;
  }

  .classroomBenefits {
    border: none;
    background-color: #3a529d;
    color: #fff;
    padding: 12px 13px;
    border-radius: 5px 5px 0px 0px;
    font-size: 12px;
    font-weight: 600;
    font-family: "Nunito", sans-serif;
  }

  .aboutFac {
    border: none;
    background-color: rgb(224, 224, 225);
    color: #000;
    padding: 12px 18px;
    border-radius: 5px 5px 0px 0px;
    font-size: 12px;
    font-weight: 600;
    font-family: "Nunito", sans-serif;
  }

  .attemptCourseDetail7 {
    position: relative;
    top: 80px;
    width: 100%;
    height: auto;
    border: 1px solid #edeaea;
    font-family: "Nunito", sans-serif;

    ul {
      padding-left: 15px;
    }

    .systemReq2 {
      padding-left: 20px;
      padding-top: 15px;
      font-size: 19px;
      font-weight: 900;
      font-family: "Nunito", sans-serif;
    }
  }

  .rightSide {
    position: relative;
    top: 40px;
    left: -40px;
    margin-bottom: 56px;
    width: 117%;
  }

  .activate2 {
    position: relative;
    right: 51px;
    width: 130%;
  }

  .variants {
    position: relative;
    top: 30px;
    border: 1px solid #c4bfbf;
    width: 100%;
    height: auto;
    border-radius: 5px;
    padding-left: 10px;
    padding-bottom: 25px;
    margin-bottom: 98px;
  }

  .oprSystem {
    width: 91%;
    margin-left: 6px;
    margin-top: 11px;
    height: 65px;
    border-bottom: 1px solid #dedcdc;
    font-family: "Nunito", sans-serif;
  }

  .varName {
    font-weight: 600;
    padding: 6px;
    margin-right: 20px;
    font-size: 13px;
    font-family: "Nunito", sans-serif;
  }

  .oprSystem h4 {
    font-weight: bold;
    font-size: 15px;
  }
}
@media only screen and (min-width: 360px) and (max-width: 768px) {
  .video-responsive {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
  }
  .attemptCourse2 {
    text-align: center;
    position: relative;
    top: 70px;
    align-content: center;
  }
  .showing {
    font-size: 15px !important;
    font-weight: 600;
    font-family: "Nunito", sans-serif;
    padding: 8px 0px;
  }
  .overLayWrap {
    background-color: rgba(196, 196, 196, 0.68);
    position: absolute;
    z-index: 99;
    width: 323px;
    height: 165px;
    left: 21px;
    justify-content: center;
    align-items: center;
  }
  .leftCourseLand {
    width: 100% !important;
    text-align: left !important;
    position: relative !important;
    left: 14px !important;
  }

  .showingDetail {
    font-size: 13px !important;
    font-weight: 600;
    margin-top: 8px;
    display: flex;
    margin-left: -14px;
  }

  .batchButtonDetail {
    background-color: #139413;
    color: #fff;
    border: none;
    padding: 7px 7px;
    border-radius: 5px;
    font-size: 10px;
    margin-top: 5px;
  }

  .flex {
    display: flex;
    font-family: Nunito, sans-serif;
    padding-bottom: 10px;
  }

  .flexSmart {
    display: flex;
    padding-bottom: 12px;
  }

  .systemReq {
    font-family: Raleway, sans-serif;
    font-weight: 900;
    font-size: 18px;
  }

  .font2 {
    font-family: Nunito, sans-serif;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 5px;
  }

  .blueBtnDetail {
    font-size: 15px;
    font-weight: 500;
    font-weight: 700;
    height: 40px;
    position: relative;
    width: 140px;
    z-index: 99;
    background-color: #3a529d;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-family: Nunito, sans-serif;
  }

  .grayBtnDetail {
    border-radius: 5px;
    font-family: Nunito, sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-weight: 700;
    height: 40px;
    left: 20px;
    position: relative;
    width: 120px;
    z-index: 9;
    background-color: #fff;
    border: 2px solid #3a529d;
    color: #000;
  }

  .grayBtnDetail1 {
    background-color: #3a529d;
    color: #fff;
    border-radius: 5px;
    font-family: Nunito, sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-weight: 700;
    height: 40px;
    left: 20px;
    position: relative;
    width: 120px;
    z-index: 9;
    border: 1px solid #3a529d;
  }

  .vidArea {
    border-radius: 5px;
    height: 185px;
    margin-bottom: 5px;
    margin-left: 17px;
    margin-top: 28px;
    width: 90%;
  }

  .video-responsive iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .deletePrice {
    color: red;
    right: 23px;
    font-size: 16px;
    font-weight: 500;
    position: relative;
  }

  .offPrice {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    text-align: center;
  }

  .rupeeColor {
    font-size: 16px;
    font-weight: 500;
    position: relative;
    color: #139413;
    left: -11px;
  }

  .buyBtnArea {
    margin-top: 30px;
    text-align: center;
  }

  .buyCourseBtn {
    background-color: #3a529d;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    height: 47px;
    width: 160px;
  }

  .applyBtnArea {
    margin-top: 20px;
    padding-bottom: 25px;
    text-align: center;
  }

  .applyCoupon {
    border-bottom: 1px solid #3a529d;
    color: #3a529d;
    font-family: Nunito, sans-serif;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 7px;
  }

  .pricesNote {
    font-family: Nunito, sans-serif;
    margin-left: 6px;
    width: 90%;
    border-bottom: 1px solid #d9d7d7;
    padding-bottom: 20px;
  }

  .video-responsive {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
  }

  .blueBtnDetail1 {
    background-color: #fff;
    border: 2px solid #3a529d;
    border-radius: 5px;
    color: #000;
    font-family: Raleway, sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-weight: 700;
    height: 40px;
    position: relative;
    width: 140px;
    z-index: 99;
  }

  .font {
    font-family: Nunito, sans-serif;
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 12px;
  }

  .attemptCourseDetail2 {
    padding: 6px 0px;
    border-bottom: 1px solid #ddd;
    width: 100%;
  }

  .attemptCourseDetail3 {
    position: relative;
    top: 45px;
    width: 101% !important;
    height: auto !important;
    border: 1px solid #edeaea;
    background-color: #f1f8ff;

    ul {
      padding-left: 0px;
    }
  }

  .attemptCourseDetail {
    position: relative;
    width: 100%;
    height: auto;
    border-bottom: 1px solid #dedbdb;
    padding: 10px 0px;
  }

  .akBookImg {
    width: 100%;
    height: auto !important;
  }

  .attemptCourseDetail4 {
    position: relative;
    top: 60px !important;
    width: 100% !important;
    height: auto !important;
    padding-bottom: 0px !important;
    border-bottom: 1px solid #e0e0e0;

    .activationProcess {
      color: #3a529d;
    }
  }

  .activate {
    position: relative;
    right: 22px;
    width: 100%;
  }

  .attemptCourseDetail5 {
    position: relative;
    top: 70px;
    width: 100%;
    height: auto;
  }

  .lectureIndex {
    color: #3a529d;
    font-size: 13px;
    font-weight: 600;
    position: relative;
    top: 3px;
  }

  .pdfImg {
    width: 35px;
    height: 35px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 8px;
  }

  .downloadArea {
    background-color: #f5f6f7;
    width: 335px;
    height: 55px;
    border-radius: 5px;
    border: 2px solid #d9d6d6;
    position: relative;
    bottom: 0px;
    left: -28px;
    font-family: "Raleway", sans-serif;
    cursor: pointer;
  }

  .attemptCourseDetail6 {
    position: relative;
    top: 80px;
    width: 100%;
  }

  .classroomBenefits {
    border: none;
    background-color: #3a529d;
    color: #fff;
    padding: 12px 13px;
    border-radius: 5px 5px 0px 0px;
    font-size: 11px;
    font-weight: 600;
    font-family: "Nunito", sans-serif;
    width: 167px;
  }

  .aboutFac {
    border: none;
    background-color: rgb(224, 224, 225);
    color: #000;
    padding: 12px 18px;
    border-radius: 5px 5px 0px 0px;
    font-size: 11px;
    font-weight: 600;
    font-family: "Nunito", sans-serif;
    width: 172px;
  }

  .attemptCourseDetail7 {
    position: relative;
    top: 80px;
    width: 100%;
    height: auto;
    border: 1px solid #edeaea;
    font-family: "Nunito", sans-serif;

    ul {
      padding-left: 15px;
    }

    .systemReq2 {
      padding-left: 20px;
      padding-top: 15px;
      font-size: 19px;
      font-weight: 900;
      font-family: "Nunito", sans-serif;
    }
  }

  .rightSide {
    position: relative;
    top: 40px;
    left: -40px;
    margin-bottom: 56px;
    width: 117%;
  }

  .activate2 {
    position: relative;
    right: 51px;
    width: 130%;
  }

  .variants {
    position: relative;
    top: 30px;
    border: 1px solid #c4bfbf;
    width: 100%;
    height: auto;
    border-radius: 5px;
    padding-left: 10px;
    padding-bottom: 25px;
    margin-bottom: 98px;
  }

  .oprSystem {
    width: 91%;
    margin-left: 6px;
    margin-top: 11px;
    height: 65px;
    border-bottom: 1px solid #dedcdc;
    font-family: "Nunito", sans-serif;
  }

  .varName {
    font-weight: 600;
    padding: 6px;
    margin-right: 20px;
    font-size: 13px;
    font-family: "Nunito", sans-serif;
  }

  .oprSystem h4 {
    font-weight: bold;
    font-size: 15px;
  }
  .ContentBelowYT {
    margin-top: 0px;
  }
}
@media screen and (width: 360px) {
  .downloadArea {
    width: 294px;
  }
  .aboutFac {
    width: 143px;
    padding: 12px 5px;
  }
  .classroomBenefits {
    width: 156px;
  }
  .variants {
    width: 100%;
  }
  .vidArea {
    margin-left: 10px;
  }
  .ContentBelowYT {
    margin-top: 0px;
  }
}
@media screen and (width: 768px) {
  .variants {
    width: 91%;
  }
  .ContentBelowYT {
    margin-top: 217px;
  }
}

@media screen and (min-width:820px) and (max-width: 1180px) {
  .video-responsive {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
  }
  .attemptCourse2 {
    text-align: center;
    position: relative;
    top: 70px;
    align-content: center;
  }
  .showing {
    font-size: 15px !important;
    font-weight: 600;
    font-family: "Nunito", sans-serif;
    padding: 8px 0px;
  }
  .overLayWrap {
    background-color: rgba(196, 196, 196, 0.68);
    position: absolute;
    z-index: 99;
    width: 323px;
    height: 165px;
    left: 21px;
    justify-content: center;
    align-items: center;
  }
  .leftCourseLand {
    width: 100% !important;
    text-align: left !important;
    position: relative !important;
    left: 14px !important;
  }

  .showingDetail {
    font-size: 13px !important;
    font-weight: 600;
    margin-top: 8px;
    display: flex;
    margin-left: -14px;
  }

  .batchButtonDetail {
    background-color: #139413;
    color: #fff;
    border: none;
    padding: 7px 7px;
    border-radius: 5px;
    font-size: 10px;
    margin-top: 5px;
  }

  .flex {
    display: flex;
    font-family: Nunito, sans-serif;
    padding-bottom: 10px;
  }

  .flexSmart {
    display: flex;
    padding-bottom: 12px;
  }

  .systemReq {
    font-family: Raleway, sans-serif;
    font-weight: 900;
    font-size: 18px;
  }

  .font2 {
    font-family: Nunito, sans-serif;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 5px;
  }

  .blueBtnDetail {
    font-size: 15px;
    font-weight: 500;
    font-weight: 700;
    height: 40px;
    position: relative;
    width: 140px;
    z-index: 99;
    background-color: #3a529d;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-family: Nunito, sans-serif;
  }

  .grayBtnDetail {
    border-radius: 5px;
    font-family: Nunito, sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-weight: 700;
    height: 40px;
    left: 20px;
    position: relative;
    width: 120px;
    z-index: 9;
    background-color: #fff;
    border: 2px solid #3a529d;
    color: #000;
  }

  .grayBtnDetail1 {
    background-color: #3a529d;
    color: #fff;
    border-radius: 5px;
    font-family: Nunito, sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-weight: 700;
    height: 40px;
    left: 20px;
    position: relative;
    width: 120px;
    z-index: 9;
    border: 1px solid #3a529d;
  }

  .vidArea {
    border-radius: 5px;
    height: 185px;
    margin-bottom: 5px;
    margin-left: 34px;
    margin-top: 28px;
    width: 90%;
  }

  .video-responsive iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .deletePrice {
    color: red;
    right: 23px;
    font-size: 16px;
    font-weight: 500;
    position: relative;
  }

  .offPrice {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    text-align: center;
  }

  .rupeeColor {
    font-size: 16px;
    font-weight: 500;
    position: relative;
    color: #139413;
    left: -11px;
  }

  .buyBtnArea {
    margin-top: 30px;
    text-align: center;
  }

  .buyCourseBtn {
    background-color: #3a529d;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    height: 47px;
    width: 160px;
  }

  .applyBtnArea {
    margin-top: 20px;
    padding-bottom: 25px;
    text-align: center;
  }

  .applyCoupon {
    border-bottom: 1px solid #3a529d;
    color: #3a529d;
    font-family: Nunito, sans-serif;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 7px;
  }

  .pricesNote {
    font-family: Nunito, sans-serif;
    margin-left: 6px;
    width: 90%;
    border-bottom: 1px solid #d9d7d7;
    padding-bottom: 20px;
  }

  .video-responsive {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
  }

  .blueBtnDetail1 {
    background-color: #fff;
    border: 2px solid #3a529d;
    border-radius: 5px;
    color: #000;
    font-family: Raleway, sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-weight: 700;
    height: 40px;
    position: relative;
    width: 140px;
    z-index: 99;
  }

  .font {
    font-family: Nunito, sans-serif;
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 12px;
  }

  .attemptCourseDetail2 {
    padding: 6px 0px;
    border-bottom: 1px solid #ddd;
    width: 100%;
  }

  .attemptCourseDetail3 {
    position: relative;
    top: 45px;
    width: 101% !important;
    height: auto !important;
    border: 1px solid #edeaea;
    background-color: #f1f8ff;

    ul {
      padding-left: 0px;
    }
  }

  .attemptCourseDetail {
    position: relative;
    width: 100%;
    height: auto;
    border-bottom: 1px solid #dedbdb;
    padding: 10px 0px;
  }

  .akBookImg {
    width: 100%;
    height: auto !important;
  }

  .attemptCourseDetail4 {
    position: relative;
    top: 60px !important;
    width: 100% !important;
    height: auto !important;
    padding-bottom: 0px !important;
    border-bottom: 1px solid #e0e0e0;

    .activationProcess {
      color: #3a529d;
    }
  }

  .activate {
    position: relative;
    right: 22px;
    width: 100%;
  }

  .attemptCourseDetail5 {
    position: relative;
    top: 70px;
    width: 100%;
    height: auto;
  }

  .lectureIndex {
    color: #3a529d;
    font-size: 13px;
    font-weight: 600;
    position: relative;
    top: 3px;
  }

  .pdfImg {
    width: 35px;
    height: 35px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 8px;
  }

  .downloadArea {
    background-color: #f5f6f7;
    width: 335px;
    height: 55px;
    border-radius: 5px;
    border: 2px solid #d9d6d6;
    position: relative;
    bottom: 0px;
    left: -28px;
    font-family: "Raleway", sans-serif;
    cursor: pointer;
  }

  .attemptCourseDetail6 {
    position: relative;
    top: 80px;
    width: 100%;
  }

  .classroomBenefits {
    border: none;
    background-color: #3a529d;
    color: #fff;
    padding: 12px 13px;
    border-radius: 5px 5px 0px 0px;
    font-size: 11px;
    font-weight: 600;
    font-family: "Nunito", sans-serif;
    width: 167px;
  }

  .aboutFac {
    border: none;
    background-color: rgb(224, 224, 225);
    color: #000;
    padding: 12px 18px;
    border-radius: 5px 5px 0px 0px;
    font-size: 11px;
    font-weight: 600;
    font-family: "Nunito", sans-serif;
    width: 172px;
  }

  .attemptCourseDetail7 {
    position: relative;
    top: 80px;
    width: 100%;
    height: auto;
    border: 1px solid #edeaea;
    font-family: "Nunito", sans-serif;

    ul {
      padding-left: 15px;
    }

    .systemReq2 {
      padding-left: 20px;
      padding-top: 15px;
      font-size: 19px;
      font-weight: 900;
      font-family: "Nunito", sans-serif;
    }
  }

  .rightSide {
    position: relative;
    top: 40px;
    left: -40px;
    margin-bottom: 56px;
    width: 117%;
  }

  .activate2 {
    position: relative;
    right: 51px;
    width: 130%;
  }

  .variants {
    position: relative;
    top: 30px;
    border: 1px solid #c4bfbf;
    width: 91%;
    height: auto;
    border-radius: 5px;
    padding-left: 10px;
    padding-bottom: 25px;
    margin-bottom: 98px;
  }

  .oprSystem {
    width: 91%;
    margin-left: 6px;
    margin-top: 11px;
    height: 65px;
    border-bottom: 1px solid #dedcdc;
    font-family: "Nunito", sans-serif;
  }

  .varName {
    font-weight: 600;
    padding: 6px;
    margin-right: 20px;
    font-size: 13px;
    font-family: "Nunito", sans-serif;
  }

  .oprSystem h4 {
    font-weight: bold;
    font-size: 15px;
  }
  .ContentBelowYT {
    margin-top: 227px;
  }
}
