body,
html {
  overflow-x: hidden !important;
}

.posterBack {
  background-color: #dee8ef;
  width: 100%;
  height: 570px;
  margin-top: 18px;
  position: relative;
}

::-webkit-scrollbar {
  display: none;
}

.rightPoster {
  position: relative;
  bottom: 0px;
  right: 123px;
}

.homeImg1 {
  width: 83%;
  height: 100%;
  position: relative;
  left: 120px;
  top: 15px;
}

.leftPoster {
  position: relative;
  top: 100px;
  left: 120px;
  text-align: left;
}

.discover {
  color: black;
  font-weight: bold;
  font-size: 18px;
  font-family: "Raleway", sans-serif;
}

.cafoundation {
  font-weight: bold;
  line-height: 32px;
  font-size: 28px;
  color: black;
  font-family: "Raleway", sans-serif;
}

.learnAt {
  line-height: 30px;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  color: black;
  position: relative;
  bottom: 15px;
}

.blueBack {
  background-color: #3a519d;
  position: absolute;
  bottom: -52px;
  left: 17%;
  width: 65%;
  height: 18%;
  border-radius: 8px;
}

.showBlueBack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.requestCallBack {
  background-color: #e2e2e2;
  border: none;
  position: relative;
  top: 20px;
  padding: 7px 12px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.2px;
  font-family: "Raleway", sans-serif;
  text-align: left;
  height: 27pt;
}

.requestPara {
  position: relative;
  top: 28px;
  color: #dbd7d7;
}

.joinCa {
  padding: 55px 0px;
}

.joinCa h2 {
  text-transform: capitalize !important;
}

.joinCa h3 {
  margin-bottom: 0;
  margin-top: 3px;
}

.join {
  font-weight: bold;
  text-align: center;
  font-family: "Raleway", sans-serif;
  color: black;
  font-size: 28px;
}

.may2022 {
  color: #077c07;
}

.batchAvail {
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  font-family: "Raleway", sans-serif;
  line-height: 35px;
}

.english {
  color: #3a519d;
}

.hiden {
  display: none;
}

.rightPoster1 {
  display: none;
}

@media only screen and (min-width: 1200px) {
  .homeImg1 {
    width: 31rem;
    height: 30rem;
    position: relative;
    left: 50rem;
    top: -250px;
  }
  .cafoundation {
    font-weight: bold;
    line-height: 32px;
    font-size: 25px;
    color: black;
    font-family: "Raleway", sans-serif;
  }
  .requestPara {
    position: relative;
    top: 28px;
    color: #dbd7d7;
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 767px) {
  body {
    overflow-x: hidden !important;
  }
  .wraper1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .leftPoster {
    top: 20px;
    margin-left: -120px;
  }
  .discover {
    color: black;
    font-weight: bold;
    font-size: 11px;
    font-family: "Raleway", sans-serif;
    z-index: 999999;
  }
  .cafoundation {
    font-weight: bold;
    line-height: 24px;
    font-size: 20px;
    color: black;
    font-family: "Raleway", sans-serif;
    z-index: 999999;
  }
  .learnAt {
    line-height: 18px;
    font-family: "Nunito", sans-serif;
    font-size: 12px;
    color: black;
    position: relative;
    bottom: 15px;
    z-index: 999999;
  }
  .rightPoster {
    position: relative;
    bottom: 0px;
    right: 60px;
  }
  .homeImg1 {
    width: 220px;
    height: 420px;
    position: relative;
    top: 15px;
  }
  .blueBack {
    display: none;
  }
  .blueBackCont {
    background-color: #3a519d;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    height: 80px;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    margin-top: 42.5px;
  }
  .hiden {
    display: block;
  }
  .posterBack {
    top: 0px !important;
    overflow-x: hidden !important;
    background-color: #dee8ef;
    width: 100%;
    height: 420px;
    position: relative;
  }
  .requestCallBack {
    background-color: #d6dcf2;
    border: none;
    position: relative;
    top: 20px;
    padding: 7px 12px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.2px;
    font-family: "Raleway", sans-serif;
    text-align: left;
    height: 27pt;
  }
  .rightPoster {
    display: none;
  }
  .rightPoster1 {
    display: block;
    left: 100px;
    height: 240px;
    width: 240px;
  }
  .joinCa {
    padding: 36px 0px;
  }
  .join {
    text-transform: capitalize;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    font-family: "Raleway", sans-serif;
    color: black;
  }
  .batchAvail {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    font-family: "Raleway", sans-serif;
  }
  .english {
    color: #3a519d;
  }
  .wingName1536 {
    display: none;
  }
  .homeImg2 {
    width: 190px;
    height: 280px;
    margin-top: 6px;
    margin-left: -117px;
    z-index: 0;
    overflow: hidden;
  }
}
