.container {
  margin-left: 60px;
}

.attemptCourse {
  text-align: center;
  position: relative;
  top: 40px;
}

.attemptCourse2 {
  text-align: center;
  position: relative;
  top: 70px;
  align-content: center;
  // display: flex;
  // justify-content: space-evenly;
  // align-items: center;
}

.batch_button {
  border: none;
  background-color: white;
  font-size: 13px;
}

.button_radio {
  height: 12px;
  width: 12 px;
}

.caTitleLanding {
  color: #fff;
  position: absolute;
  top: 113px;
  left: 50px;
}

.showing {
  font-size: 23px;
  font-family: "Raleway", sans-serif !important;
  font-weight: bold !important;
  margin-right: 106px;
  color: #414141;
}

.selectAtt {
  width: 10%;
  height: 38px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 700;
  font-family: "Nunito", sans-serif;
}

.jun {
  color: #139413;
}

.blueBtn {
  border: none;
  width: 100px;
  height: 32px;
  background-color: #3a529d;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  border-radius: 20px;
  z-index: 99;
  position: relative;
}

.blueBtnSelect {
  border: none;
  width: 100px;
  height: 32px;
  background-color: #3a529d;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  border-radius: 20px;
  z-index: 99;
  position: relative;
  right: 25px;
}

.grayBtn {
  border: none;
  width: 120px;
  height: 32px;
  background-color: rgb(213, 213, 218);
  color: #000;
  font-weight: 500;
  font-size: 15px;
  border-radius: 20px;
  position: relative;
  right: 25px;
  z-index: 9;
}

.grayBtnSelect {
  border: none;
  width: 120px;
  height: 32px;
  background-color: rgb(213, 213, 218);
  color: #000;
  font-weight: 500;
  font-size: 15px;
  border-radius: 20px;
  position: relative;
  left: 0px;
  z-index: 9;
}

.leftCourseLand {
  // background-color: antiquewhite;
  width: 86%;
  position: absolute;
  left: 7%;
  text-align: left;
}

.lectureNote {
  background-color: #3a529d;
  width: 100%;
  height: 70px;
  padding: 8px;
  position: relative;
  top: 12px;
  color: #fff;
  border-radius: 5px;
}

.lectures {
  font-size: 15px;
  padding-left: 7px;
}

.typeName {
  font-weight: 600;
}

.checkDiv {
  font-size: 16px;
  padding-top: 10px;
}

.checkDiv1 {
  font-size: 13px;
  padding-top: 10px;
}

.courseDivArea {
  border: 1px solid #d1caca;
  height: 419px !important;
  margin-top: -10px;
  width: 32%;
  // width: 294px;
  // border-radius: 5px;
  .productImg {
    width: 100px;
  }
}

.batchbtnclass {
  position: relative;
  left: 76px;
}

.batchButton {
  border: none;
  font-family: "Nunito", sans-serif;
  background-color: #139413;
  color: #fff;
  height: 40px;
  width: 140px;
  border-radius: 5px 5px 0px 0px;
  font-size: 14px !important;
}

.registration {
  font-family: "Nunito", sans-serif;
  text-align: center;
  color: #ea3737;
  padding: 0 9px;
  font-weight: 700;
  // margin: 0px;
  font-size: 12px;
  margin-top: 6px !important;
  letter-spacing: 1px;
  margin-left: 8px !important;
}

.registrationSubject {
  font-family: "Nunito", sans-serif;
  text-align: center;
  color: #ea3737;
  padding: 0 9px;
  font-weight: 700;
  // margin: 0px;
  font-size: 12px;
  margin-top: 6px !important;
  letter-spacing: 1px;
  margin-left: 0px !important;
}

.batchBackColor {
  background-color: rgba(195, 187, 188, 0.5);
  // background-color:#88bfaa;
  height: 165px;
  border-radius: 5px;
  position: relative;
  // top: -2px;
}

.akPicLnad {
  width: 125px;
  height: 165px;
  position: relative;
  right: 30px;
}

.courseRightArea {
  position: relative;
  top: 25px;
  left: 10px;
}

.subjTitle {
  color: #206e20;
}

.coursenametitle {
  color: #fff;
}

.daysAreaCourse {
  width: 100%;
  height: 209px !important;
  position: absolute;
  top: 100%;
  left: 0px;
  border-bottom: 1px solid #ebebeb !important;

  @media only screen and (max-width: 360px) {
    .subnaame {
      font-size: 16px !important;
      font-weight: bold !important;
      padding: 0 36px !important;
    }
  }
}

.daysDivCourse {
  position: relative;
  left: 0px;
  top: 5px;
  width: 258px;
  margin-left: auto;
}

.daysCourse {
  text-align: left;
  font-size: 13px !important;
  margin: 5px 0px;
  padding: 0 0 0 0 !important;
}

.enrollAreaCourse {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 367px;
  left: 0px;
}

.hexTime {
  color: rgb(7, 124, 7);
  position: absolute;
  // right: 22px;
  font-size: 14px;
  left: 23px;
  top: 10px;
}

.enrollBtnCourse {
  background-color: #6dc23a;
  border: none;
  border-radius: 5px;
  width: 100px;
  height: 33px;
  color: #fff;
  font-size: 14px;
  position: relative;
  font-family: "Nunito", sans-serif;
  top: 12px;
  right: 6px;
}

.courseAmt {
  color: rgb(7, 124, 7);
  position: absolute;
  top: 6px;
  left: -10px;
  font-size: 18px;
  font-weight: 600;
}

@media only screen and (max-width: 360px) {
  .courseAmt {
    left: 13px !important;
  }
}

.batchSystem {
  border: 1px solid #c5bebe;
  width: 932px;
  height: auto;
  padding: 15px;
}

.questions {
  font-weight: "bold";
  cursor: pointer;
  font-family: "Nunito", sans-serif;
}

.arrowHexRight {
  text-align: right;
  position: relative;
  top: 0px;
  cursor: pointer;
}

.arrowHexBottom {
  text-align: right;
  position: relative;
  top: 5px;
  cursor: pointer;
}

.Responsive {
  display: none;
}

.Web {
  display: block;
}

@media only screen and (min-width: 1200px) {
  .batchButton {
    border: none;
    font-family: "Nunito", sans-serif;
    background-color: #139413;
    color: #fff;
    height: 40px;
    width: 129px;
    border-radius: 5px 5px 0px 0px;
    font-size: 18px;
    margin-left: 5px;
    font-weight: 900;
  }

  .enrollBtnCourse {
    background-color: #139413;
    border: none;
    border-radius: 5px;
    width: 100px;
    height: 33px;
    color: #fff;
    font-size: 13px;
    position: relative;
    top: 12px;
    right: 6px;
    font-weight: bolder;
    font-size: 14px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 768px) {
  .courseAmt {
    left: 32px;
  }
  .container {
    width: auto !important;
  }
  .CoursesPage .section1 {
    height: 56px !important;
  }

  .CoursesPage .section1 h1 {
    font-size: 14px !important;
    margin-top: 0px;
    // padding-left: 92px !important;
    // margin-left: 45px !important;
    margin-left: auto !important;
  }

  .Responsive {
    display: block;
  }

  .Web {
    display: none;
  }

  .caTitleLanding {
    top: 69px !important;
    font-size: 19px !important;
    left: 13px !important;
  }

  .lectureNote {
    background-color: #fff !important;
    width: 100%;
    height: 70px;
    padding: 8px;
    position: relative;
    top: 12px;
    color: #000 !important;
    border-radius: 5px;
  }

  .spanRow {
    display: flex;
    width: 100%;
  }

  .lectureAvailable {
    h5 {
      font-size: 10px;
      width: 100%;
      margin-top: 13px;
    }
  }

  .hindiBtnSelected {
    background: #3a529d;
    color: #fff;
    border: none;
    padding: 5px 17px;
    border-radius: 14px;
    text-align: center;
    margin-left: 212px;
    margin-top: 8px;
    position: absolute;
    z-index: 99;
    width: 81px;
  }

  .englishBtnSelected {
    background: #3a529d;
    color: #fff;
    border: none;
    padding: 5px 17px;
    border-radius: 14px;
    text-align: center;
    margin-left: 8px;
    margin-top: 8px;
    position: absolute;
    z-index: 99;
    position: absolute;
    right: 8px;
    width: 81px;
  }

  .englishBtnGray {
    background: gray;
    color: #fff;
    border: none;
    padding: 5px 17px;
    border-radius: 14px;
    text-align: center;
    margin-left: 16px;
    margin-top: 8px;
    position: absolute;
    right: 8px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 81px;
  }

  .hindiBtnGray {
    background: gray;
    color: #fff;
    border: none;
    padding: 5px 17px;
    border-radius: 14px;
    text-align: center;
    margin-left: 216px;
    margin-top: 8px;
    position: absolute;
    z-index: 99;
    width: 81px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .attemptWrapper {
    margin-top: 14px;
  }

  .attemptTitle {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-left: -12px;
  }

  .selectAttempts {
    width: 50%;
    height: 38px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 700;
    font-family: "Nunito", sans-serif;
    border: 1px solid #e0e0e0;
    background-color: #fff;
    color: #787878;
    // color: ;
  }

  .subjectFilterBtn {
    background: #3a529d !important;
    color: #fff;
    border: none;
    width: 48%;
    height: 39px;
    border-radius: 8px;
    margin-left: 6px;
    font-weight: 500;
    font-size: 11px;
    padding: 0px 5px;
  }

  .batchWrapper {
    display: inline-flex;
    width: 100%;
    margin-left: -17px;
    margin-top: 20px;
    border-bottom: 1px solid #ddd;
  }

  .wingBtn {
    background: #3a529d;
    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: nneo;
    border: none;
    padding: 5px;
    width: 143px;
    font-size: 13px;
    margin: 0px 5px;
  }

  .registrationendsText {
    text-align: center;
    color: #ea3737;
  }

  .udanBtn {
    border: none;
    background-color: #139413;
    color: #fff;
    height: 46px;
    width: 115px;
    border-radius: 5px 5px 0px 0px;
    margin-left: 21px;
    font-size: 12px;
  }

  .courseWrap {
    margin-top: 35px;
    margin-left: -10px;
  }

  .courseRightAreas {
    position: absolute;
    top: 20px;
    right: 10px;
  }

  .hexTime {
    color: rgb(7, 124, 7);
    position: absolute;
    top: 7px;
    font-size: 18px;
    left: 0px;
  }

  .enrollBtnCourse {
    right: 11px;
    font-weight: bold;
  }

  .courseDivArea {
    border: 1px solid #ebebeb !important;
    height: 419px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .batchSystem {
    border: 1px solid #c5bebe !important;
    width: 100% !important;
    height: auto;
    padding: 8px !important;
    width: 98% !important;
  }

  .questions {
    font-weight: 900 !important;
    cursor: pointer;
    font-family: "Nunito", sans-serif;
    font-size: 14px;
  }
}
@media only screen and (min-width:820px) and (max-width: 1180px) {
  .courseAmt {
    left: 23px;
  }
  .CoursesPage .section1 {
    height: 56px !important;
  }

  .CoursesPage .section1 h1 {
    font-size: 15px !important;
    margin-top: 0px;
    // padding-left: 92px !important;
    // margin-left: 45px !important;
    margin-left: auto !important;
  }

  .Responsive {
    display: block;
  }

  .Web {
    display: none;
  }

  .caTitleLanding {
    top: 69px !important;
    font-size: 19px !important;
    left: 13px !important;
  }

  .lectureNote {
    background-color: #fff !important;
    width: 100%;
    height: 70px;
    padding: 8px;
    position: relative;
    top: 12px;
    color: #000 !important;
    border-radius: 5px;
  }

  .spanRow {
    display: flex;
    width: 100%;
  }

  .lectureAvailable {
    h5 {
      font-size: 10px;
      width: 100%;
      margin-top: 13px;
    }
  }

  .hindiBtnSelected {
    background: #3a529d;
    color: #fff;
    border: none;
    padding: 5px 17px;
    border-radius: 14px;
    text-align: center;
    margin-left: 54%;
    margin-top: 8px;
    position: absolute;
    z-index: 99;
    width: 81px;
  }

  .englishBtnSelected {
    background: #3a529d;
    color: #fff;
    border: none;
    padding: 5px 17px;
    border-radius: 14px;
    text-align: center;
    margin-left: 8px;
    margin-top: 8px;
    position: absolute;
    z-index: 99;
    position: absolute;
    right: 16px;
    width: 81px;
  }

  .englishBtnGray {
    background: gray;
    color: #fff;
    border: none;
    padding: 5px 17px;
    border-radius: 14px;
    text-align: center;
    margin-left: 16px;
    margin-top: 8px;
    position: absolute;
    right: 26px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 81px;
  }

  .hindiBtnGray {
    background: gray;
    color: #fff;
    border: none;
    padding: 5px 17px;
    border-radius: 14px;
    text-align: center;
    margin-left: 56%;
    margin-top: 8px;
    position: absolute;
    z-index: 99;
    width: 81px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .attemptWrapper {
    margin-top: 14px;
  }

  .attemptTitle {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-left: -12px;
  }

  .selectAttempts {
    width: 50%;
    height: 38px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 700;
    font-family: "Nunito", sans-serif;
    border: 1px solid #e0e0e0;
    background-color: #fff;
    color: #787878;
    // color: ;
  }

  .subjectFilterBtn {
    background: #3a529d !important;
    color: #fff;
    border: none;
    width: 48%;
    height: 39px;
    border-radius: 4px;
    margin-left: 6px;
    font-weight: 500;
    padding: 6px;
  }

  .batchWrapper {
    display: inline-flex;
    width: 100%;
    margin-left: -17px;
    margin-top: 20px;
  }

  .wingBtn {
    background: #3a529d;
    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: nneo;
    border: none;
    padding: 5px;
    width: 143px;
    font-size: 13px;
    margin: 0px 5px;
  }

  .registrationendsText {
    text-align: center;
    color: #ea3737;
  }

  .udanBtn {
    border: none;
    background-color: #139413;
    color: #fff;
    height: 46px;
    width: 122px;
    border-radius: 5px 5px 0px 0px;
    margin-left: -10px;
    font-size: 12px;
  }

  .courseWrap {
    margin-top: 35px;
    margin-left: -10px;
  }

  .courseRightAreas {
    position: absolute;
    top: 20px;
    right: 10px;
  }

  .hexTime {
    color: rgb(7, 124, 7);
    position: absolute;
    top: 7px;
    font-size: 18px;
    left: 0px;
  }

  .enrollBtnCourse {
    right: 11px;
    font-weight: bold;
  }

  .courseDivArea {
    border: 1px solid #ebebeb !important;
    height: 419px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 94%;
  }

  .batchSystem {
    border: 1px solid #c5bebe !important;
    width: 100% !important;
    height: auto;
    padding: 8px !important;
    width: 98% !important;
  }

  .questions {
    font-weight: 900 !important;
    cursor: pointer;
    font-family: "Nunito", sans-serif;
    font-size: 14px;
  }
}
// newCss
.CoursesPage {
  .section1 {
    background-image: url("../images/sub_back.png");
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 70px;

    h1 {
      font-size: 25px;
      font-weight: bold;
      padding: 10px;
      padding-left: 14px;
      color: #fff;
      padding-top: 20px;
      margin-left: 68px;
    }
  }

  .section2 {
    text-align: center;

    .selectAttCourse {
      width: 10%;
      height: 38px;
      border-radius: 5px;
      font-size: 15px;
      font-weight: 500;
      border-color: #ddd;
      color: #414141;
    }
  }

  .section3 {
    margin-top: 50px;

    .batchType {
      border: 1px solid #eaeaea;
      width: 100%;
      height: auto;
      border-radius: 5px;
      padding-left: 25px;
      padding-bottom: 25px;
      margin-top: 38px;
    }

    .chooseSubjects {
      border: 1px solid #ebebeb;
      width: 100%;
      height: auto;
      border-radius: 5px;
      padding-left: 25px;
      padding-bottom: 25px;
      margin-top: 30px;
    }
  }

  .section4 {
    .flexWrap {
      display: inline-flex;
      justify-content: space-between;
    }

    .margSec4 {
      margin-right: 21px;
      margin-left: 15px;
      margin-bottom: 10px;
    }

    .subnaame {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      width: 100%;
      margin-bottom: 2px;

      padding: 5px 32px;
    }

    .newRes {
      padding: 0;
    }
  }
}

@media only screen and (max-width: 360px) {
  .mobContainer {
    margin-left: auto !important;
  }

  .container {
    margin-left: auto !important;
  }
  .udanBtn {
    border: none;
    background-color: #139413;
    color: #fff;
    height: 46px;
    width: 98px;
    border-radius: 5px 5px 0px 0px;
    font-size: 12px;
  }
  .hindiBtnSelected {
    margin-left: 7px;
  }
  .hindiBtnGray {
    margin-left: 13px;
  }
  .courseDropdown {
    width: 100% !important;
  }
}
@media only screen and (width: 768px) {
  .courseDivArea {
    width: 93%;
  }
  .udanBtn {
    margin-left: -10px;
  }
  .courseAmt {
    left: 22px;
  }
}
.contain2 {
  margin-left: 140px;
}
